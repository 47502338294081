.header-color {
  background: #30E8BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FF8235, #30E8BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF8235, #30E8BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.landing-grid {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logo-image {
  height: 250px;
  padding-top: 7em;
  border-radius: 50%;
}

.banner-text {
  background: #30E8BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FF8235, #30E8BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF8235, #30E8BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.projects-grid {
  display: flex;
}

/*contact page css*/
.contact-body{
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid{
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 550px;
  background: linear-gradient(to right, #FF8235, #30E8BF);
  color: white;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
  color: white;
}

/*contact page css*/

/*resume page css*/
.resume-right-col {
  background: #30E8BF;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #FF8235, #30E8BF);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #FF8235, #30E8BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  padding: 2em;
  border-radius: 75px;
}
.resume-left-content {
  background: linear-gradient(to right, #FF8235, #30E8BF);
}
/*resume page css*/
/*project background*/
.mdl-layout__content {
  color: linear-gradient(to right, #FF8235, #30E8BF);
  background: linear-gradient(to right, #FF8235, #30E8BF);
}
